import React from 'react'
import { Box, List, Text } from '@mantine/core'

const Terms = () => {
  return (
    <Box>
      <Text><strong>General</strong></Text>
      <List withPadding>
        <List.Item>Receipt of the signed Booking Form confirms that you accept these terms and conditions</List.Item>
        <List.Item>We reserve the right to refuse any booking, cancel the booking anytime or refuse to open the centre if the proposed hire is in breach of our terms & conditions or company policies</List.Item>
        <List.Item>We do not warrant that the premises are suitable for the type of function being undertaken</List.Item>
        <List.Item>The terms and conditions and charges contained herein may vary from time to time</List.Item>
      </List>

      <Text mt='sm'><strong>Bookings & Payment</strong></Text>
      <List withPadding>
        <List.Item>
          The booking is only confirmed:
          <List withPadding>
            <List.Item>On receipt of a confirmation email from the supplier</List.Item>
            <List.Item>On receipt of a £100 deposit</List.Item>
            <List.Item>On receipt of full payment for the booking</List.Item>
          </List>
          If any of these are not received <strong>seven days</strong> before the date of the hire, it will be cancelled
        </List.Item>
        <List.Item>On-Going bookings require four weeks of hire fees paid in advance</List.Item>
        <List.Item>If the function extends beyond the booking period, additional charges will be applied in 30-minute increments</List.Item>
        <List.Item>Payment can be made by direct bank transfer</List.Item>
      </List>

      <Text mt='sm'><strong>Cancellation</strong></Text>
      <List withPadding>
        <List.Item>Written notification must be received confirming that you wish to cancel the booking.</List.Item>
        <List.Item>On-going bookings require one month's notice that the hire is to be cancelled.</List.Item>
        <List.Item>If the booking is cancelled <strong>more than 7 days</strong> before the hire date, all deposits and fees will be refunded minus a £50 cancellation fee. If the booking is cancelled <strong>within 7 days</strong> of the hire date, the £100 deposit will be retained.</List.Item>
        <List.Item>Transfer of a confirmed booking to an alternate date will be considered as a separate booking, and you will remain liable for all charges due on the original booking.</List.Item>
      </List>

      <Text mt='sm'><strong>Hire General</strong></Text>
      <List withPadding>
        <List.Item>The time required for setting up and tidying, including all cleaning, must be included within the hire period.</List.Item>
        <List.Item>On arrival, you are required to liaise with the Duty Officer. A member of our staff will be on duty during the hire period. The Hirer must adhere to any instructions given by the Duty Officer.</List.Item>
        <List.Item>Guests attending the hire will not exceed the pre-agreed number.</List.Item>
        <List.Item>We reserve the right of entry at all times to N1C Centre facilities. Only staff employed by N1C Centre have the authority to allow access.</List.Item>
        <List.Item>Any equipment or furniture made available by us for you to use should be returned in the condition given at the start of the hire.</List.Item>
        <List.Item>You will not affix or hang any object to any part of the N1C Centre without our prior consent.</List.Item>
        <List.Item>You are responsible for any damage to the building or property in the areas under hire. If any damage occurs, you undertake to compensate us promptly upon request.</List.Item>
        <List.Item>Where applicable, you will need to clean the N1C Centre before the end of the hire.</List.Item>
      </List>

      <Text mt='sm'><strong>Hire Conduct</strong></Text>
      <List withPadding>
        <List.Item>You are required to conduct and manage the function in a proper, orderly and lawful manner.</List.Item>
        <List.Item>You shall not permit any act that may harm the reputation of N1C Centre, including nuisance to residents.</List.Item>
        <List.Item>We reserve the right to cease an event or remove any persons from the premises if their behaviour is considered inappropriate.</List.Item>
        <List.Item>Sound levels should be kept at a reasonable level, and we reserve the right to control them if required.</List.Item>
        <List.Item>You should ensure that their guests leave at the specified time and are respectful of residents.</List.Item>
        <List.Item>Smoking is not permitted in any part of the hired facilities.</List.Item>
        <List.Item>Consuming alcohol on the hired premises and directly outside is strictly prohibited.</List.Item>
        <List.Item>The provision, supply, or use of illegal substances is strictly prohibited in or around the hired premises.</List.Item>
      </List>

      <Text><strong>Breach of the above could lead to immediate cancellation of the event, forfeit of the deposit and the hire fees and potential police involvement.</strong></Text>

      <Text mt='sm'><strong>Health & Safety</strong></Text>
      <List withPadding>
        <List.Item>Your responsibility is to familiarise yourself and your guests with N1C Centre fire exits, and escape routes are kept clear and unobstructed.</List.Item>
        <List.Item>You are responsible for the health & safety of your guests.</List.Item>
        <List.Item>A first aid box is located at a reception in the event of any accidents.</List.Item>
        <List.Item>No changes to the structure or fixtures & fittings may be made to the N1C Centre.</List.Item>
        <List.Item>The use of special effects, including smoke and dry ice machines, fireworks, and live ammunition, is prohibited in all areas of the N1C Centre.</List.Item>
        <List.Item>No flammable liquids or other dangerous substances shall be brought onto the premises.</List.Item>
      </List>

      <Text mt='sm'><strong>Deliveries, Storage & Removal</strong></Text>
      <List withPadding>
        <List.Item>You must advise us of any deliveries in advance and mark all deliveries with the function's name, date, and venue.</List.Item>
        <List.Item>All your items must be removed at the end of the function.</List.Item>
        <List.Item>We will not accept any responsibility for the damage or loss of goods left on the premises before, during, or after the function.</List.Item>
      </List>

      <Text mt='sm'><strong>Publicity & Insurance</strong></Text>
      <List withPadding>
        <List.Item>The use of our name or logo on any promotional material is strictly forbidden</List.Item>
        <List.Item>The use of any displays, posters or free-standing banners must have prior approval</List.Item>
        <List.Item>You are not allowed to conduct any trading activity on the premises</List.Item>
        <List.Item>You are responsible for insurance to cover the hire period. This includes all equipment and any other
          liability, including Public Liability</List.Item>
        <List.Item>You must immediately notify us in writing of any occurrence or accident likely to give rise to a
          claim under an insurance policy</List.Item>
      </List>

      <Text mt='sm'><strong>Indemnity</strong></Text>
      <Text size='md'>You indemnify our staff and us against any liability, loss or claim arising under any statute or
        at common law in respect of injury, loss or damage to property, or respect of personal injury to, or death of,
        any person arising out of or in connection with the function.</Text>

      <Text mt='sm'><strong>Compliance with Status, Licensing and Regulations</strong></Text>
      <Text size='md'>You will be required to have the appropriate licence/s from licensing authorities or the local
        council. For example, music licences (PPL and PRS) if recorded music or live music is played. We will not be
        liable for any fees payable for licences or any costs incurred by the Hirer if a license is not obtained.</Text>
    </Box>
  )
}

export default Terms
