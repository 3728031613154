import React, { useEffect, useState } from 'react'
import { BookingRequest } from '@sydrc/common'
import {
  Box,
  Button,
  Center,
  Group,
  Image,
  ScrollArea,
  Text, TextInput,
  Title, useMantineTheme,
} from '@mantine/core'
import { useNotifications } from '@mantine/notifications'
import { useModals } from '@mantine/modals'
import { baseUrl, safeFetch } from './util/api'

const Keyword = ({ children }: React.PropsWithChildren<any>) => {
  const theme = useMantineTheme()
  return (<span style={{ fontWeight: 'bold', fontFamily: 'monospace', color: theme.colors.dark[7] }}>
    {children}
  </span>)
}

const Word = ({ children }: React.PropsWithChildren<any>) => (
  <span style={{ marginLeft: '10px', marginRight: '10px' }}>
    {children}
  </span>
)

const BookingConfirmationForm = (props: BookingRequest & { secret: string | null }) => {
  const { date, room, start, end, name, organization, phone, email, description, supplies, recurrance, until, secret } = props

  const [requestOut, setRequestOut] = useState<null | 'confirm' | 'deny'>(null)
  const notifications = useNotifications()
  const modals = useModals()

  const [declineReason, setDeclineReason] = useState('Unspecified')

  const accept = async () => {
    setRequestOut('confirm')
    const [status, , error] = await safeFetch(`${baseUrl}/events?response=accepted`, {
      method: 'post',
      body: JSON.stringify(props),
    })

    setRequestOut(null)
    if (status === 200) {
      modals.openModal({
        title: <Text size='xl' weight='bold'>Booking Confirmed</Text>,
        children: (<>
          <Text>The booking has been confirmed and the client has been notified.</Text>
        </>),
        onClose() {
          window.close()
        },
      })
    } else {
      notifications.showNotification({
        title: 'An error has occurred!',
        message: <>
          <Text size='sm'>The booking was not confirmed. Please try again later.</Text>
          {error != null && <Text size='sm'>Error: {error}</Text>}
        </>,
        color: 'red',
      })
    }
  }

  const deny = async () => {
    setRequestOut('deny')
    const [status, , error] = await safeFetch(`${baseUrl}/events?response=denied&reason=${encodeURIComponent(declineReason)}`, {
      method: 'post',
      body: JSON.stringify(props),
    })

    setRequestOut(null)
    if (status === 200) {
      modals.openModal({
        title: <Text size='xl' weight='bold'>Booking Denied</Text>,
        children: (<>
          <Text>The booking has been denied and the client has been notified.</Text>
        </>),
        onClose() {
          window.close()
        },
      })
    } else {
      notifications.showNotification({
        title: 'An error has occurred!',
        message: <>
          <Text size='sm'>The booking was not confirmed. Please try again later.</Text>
          {error != null && <Text size='sm'>Error: {error}</Text>}
        </>,
        color: 'red',
      })
    }
  }

  useEffect(() => {
    if (secret) return
    notifications.showNotification({
      title: 'An error has occurred.',
      message: 'We are not sure what happened. Please re-open the link from the email!',
      color: 'red',
      autoClose: false,
    })
  }, [secret])

  let roomName = room
  if (roomName.startsWith('N1C'))
    roomName = roomName.substring(6)
  roomName = roomName.replace(/\s/, '')

  return <Box>
    <Center>
      <Title mb='lg'>Confirmation</Title>
    </Center>

    <ScrollArea>
      <Image
        src={`./img/${roomName}.jpg`}
        radius='sm'
      />

      <Group mt='xs' position='left'>
        <Text>
          <Keyword>ON</Keyword>
          <Word>{date}</Word>
          <Keyword>FROM</Keyword>
          <Word>{start}</Word>
          <Keyword>TO</Keyword>
          <Word>{end}</Word>
        </Text>
      </Group>

      {recurrance !== 'None' && (
        <Group mt='xs' position='left'>
          <Text>
            <Keyword>RECURRING</Keyword>
            <Word>{recurrance}</Word>
            <Keyword>UNTIL</Keyword>
            <Word>{until}</Word>
          </Text>
        </Group>
      )}

      <Group mt='xs'>
        <Text>
          <Keyword>BY</Keyword>
          <Word>{name}</Word>
          ({organization})
        </Text>
      </Group>

      <Group mt='xs'>
        <Text>
          <Keyword>CONTACT</Keyword>
          <Word>{email}</Word>
          <Keyword>OR</Keyword>
          <Word>{phone}</Word>
        </Text>
      </Group>

      <Group mt='xs'>
        <Text>
          <Keyword>DESCRIPTION</Keyword>
          <Word>{description}</Word>
        </Text>
      </Group>

      <Group mt='xs'>
        <Text>
          <Keyword>SUPPLIES</Keyword>
          <Word>{supplies}</Word>
        </Text>
      </Group>

      <Group mt='xs' grow>
        <TextInput
          label='Reason for declining the booking'
          description='When accepting a booking, this field will be ignored.'
          value={declineReason}
          onChange={e => setDeclineReason(e.target.value)}
          disabled={requestOut != null}
          />
      </Group>

      <Group mt='md' position='apart'>
        <p />
        <Button
          onClick={deny}
          loading={requestOut === 'deny'}
          disabled={requestOut === 'confirm'}
          color='red'>
          Decline
        </Button>
        <Button
          onClick={accept}
          loading={requestOut === 'confirm'}
          disabled={requestOut === 'deny'}>
          Accept
        </Button>
        <p />
      </Group>
    </ScrollArea>
  </Box>
}

export default BookingConfirmationForm
