import React, { useCallback, useState } from 'react'
import { NameToRoom, RoomId, RoomName, RoomToName } from './util/rooms'

import {
  Anchor,
  Box,
  Button,
  Center,
  Group,
  Image,
  NativeSelect,
  ScrollArea,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { DatePicker, TimeInput } from '@mantine/dates'
import { baseUrl, safeFetch } from './util/api'
import { useNotifications } from '@mantine/notifications'
import { BookingRequest, Recurrance } from '@sydrc/common'
import { useModals } from '@mantine/modals'
import Terms from './Terms'

const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
const EMAIL_REGEX = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/

function formatInt(num: number): string {
  return num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
}

const BookingRequestForm = (): JSX.Element => {
  const modals = useModals();
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      date: new Date() as Date,
      start: null as (Date | null),
      end: null as (Date | null),
      recurring: 'None',
      recurringUntil: null as (Date | null),
      room: 4 as RoomId,
      name: '',
      organization: '',
      phone: '',
      email: '',
      description: '',
      supplies: '',
    },
    validate: {
      date: (_, values) => {
        const { date, start, end } = values
        // If dates are not set, its not an error.
        if (!start || !end) return null

        // Date may not be in the past
        if (date.getTime() < new Date().setHours(0, 0, 0, 0))
          return 'Bookings cannot be created for times in the past'

        // Start must be before end
        if (end.getTime() <= start.getTime())
          return 'Bookings must have a positive length'

        // Start must be after 9am, end must be before 11pm
        if (start.getHours() < 9 || end.getHours() > 22)
          return 'Bookings can only be made between 9am and 11pm'

        return null
      },
      room: (value) => (value === 8 || value === 4 || value === 7 || value === 9) ? null : 'Unknown room',
      phone: (value) => PHONE_REGEX.test(value as string) ? null : 'Please enter a valid phone number',
      email: (value) => EMAIL_REGEX.test(value as string) ? null : 'Please enter a valid email address',
    },
  })

  const notifications = useNotifications()
  const handleSubmit = useCallback((v: typeof form.values) => {
    setLoading(true)

    const body: BookingRequest = {
      date: v.date.toISOString().split('T')[0],
      start: `${formatInt(v.start!.getUTCHours())}:${formatInt(v.start!.getUTCMinutes())}`,
      end: `${formatInt(v.end!.getUTCHours())}:${formatInt(v.end!.getUTCMinutes())}`,
      recurrance: v.recurring as Recurrance,
      until: v.recurring === 'None' ? undefined : (v.recurringUntil ?? new Date()).toISOString().split('T')[0],
      room: RoomToName[v.room],
      name: v.name,
      organization: v.organization,
      phone: v.phone,
      email: v.email,
      description: v.description,
      supplies: v.supplies,
    }

    safeFetch<number>(`${baseUrl}/book`, {
      method: 'POST', body: JSON.stringify(body),
    }).then(([status, value, error]) => {
      setLoading(false)
      if (status !== 200) {
        notifications.showNotification({
          title: 'Unable to request booking!',
          message: error || 'Please try again later.',
          color: 'red',
        })
        return
      }

      notifications.showNotification({
        title: 'Booking requested!',
        message: 'Please check your email for confirmation!',
        color: 'green',
      })
    })
  }, [form, notifications])

  const currentRoomName = RoomToName[form.values.room]

  const dateError = form.getInputProps('date').error

  const openTCModal = () => {
    modals.openModal({
      title: <Title>Terms and Conditions</Title>,
      size: 'xl',
      children: (<Terms />)
    })
  }

  return (<Box>
    <Center>
      <Title mb='lg'>Book a room</Title>
    </Center>

    <ScrollArea>
      <form onSubmit={form.onSubmit(handleSubmit)}>

        <Image
          src={`./img/${currentRoomName.replace(' ', '')}.jpg`}
          radius='sm'
        />

        <NativeSelect
          data={Object.values(RoomToName)}
          placeholder='Choose a room'
          disabled={loading}
          required
          error={form.getInputProps('room').error}
          value={RoomToName[form.getInputProps('room').value as RoomId]}
          onChange={event => {
            const newRoom = NameToRoom[event.target.value as RoomName]
            form.setFieldValue('room', newRoom)
          }}
          mt='md'
        />

        <Group grow mt='xs'>

          <DatePicker
            label='Booking date'
            placeholder='YY/MM/DD'
            disabled={loading} required
            allowLevelChange={false}
            minDate={new Date()}
            clearable={false}
            {...form.getInputProps('date')}
            error={null}
          />

          <Group grow>
            <TimeInput
              label='Start'
              disabled={loading} required
              clearable
              {...form.getInputProps('start')}
            />

            <TimeInput
              label='End'
              disabled={loading} required
              clearable
              {...form.getInputProps('end')}
            />
          </Group>
        </Group>

        {dateError && <Text size='sm' mt={2} color='red'>{dateError}</Text>}

        <Group grow mt='sm'>
          <NativeSelect
            data={['None', 'Daily', 'Weekly', 'Odd Weeks', 'Monthly']}
            label='Recurring'
            disabled={loading} required
            {...form.getInputProps('recurring')}
          />

          {form.values.recurring !== 'None' && (
            <DatePicker
              label='Until'
              placeholder='YY/MM/DD'
              disabled={loading} required
              allowLevelChange={false}
              minDate={form.values.date ?? new Date()}
              clearable={false}
              {...form.getInputProps('recurringUntil')}
              error={null}
            />
          )}
        </Group>

        <Group grow mt='xs'>
          <TextInput
            label='Full Name'
            maxLength={80}
            disabled={loading} required
            {...form.getInputProps('name')}
          />

          <TextInput
            label='Organization'
            maxLength={80}
            disabled={loading}
            {...form.getInputProps('organization')}
          />
        </Group>

        <TextInput
          label='Phone Number'
          placeholder='+44 (000)-000-0000'
          maxLength={80}
          disabled={loading} required
          {...form.getInputProps('phone')}
          mt='xs'
        />

        <TextInput
          label='Email'
          placeholder='your@email.com'
          maxLength={80}
          disabled={loading} required
          {...form.getInputProps('email')}
          mt='xs'
        />

        <Textarea
          label='Description'
          placeholder='Birthday party for 10 year old'
          description='Describe what you will be using the room for'
          maxLength={300}
          autosize minRows={3} maxRows={6}
          disabled={loading} required
          {...form.getInputProps('description')}
          mt='xs'
        />

        <Textarea
          label='Required Supplies'
          placeholder='e.g. tables, chairs, projector, flipchart board, room layout'
          maxLength={300}
          autosize minRows={3} maxRows={6}
          disabled={loading} required
          {...form.getInputProps('supplies')}
          mt='xs'
        />

        <Group position='right' mt='md'>
          <Text size='xs'>When a booking is confirmed, you are liable to the&nbsp;
            <Anchor size='xs' onClick={openTCModal}>Terms and Conditions</Anchor></Text>
        </Group>

        <Group position='right' mt='md'>
          <Button type='submit' loading={loading}>Submit</Button>
        </Group>
      </form>
    </ScrollArea>
  </Box>)
}

export default BookingRequestForm
