import React from 'react'
import queryString, { ParsedQuery } from 'query-string'

import { Container } from '@mantine/core'

import { BookingRequest, Recurrance } from '@sydrc/common'
import BookingRequestForm from './BookingRequestForm'
import BookingConfirmationForm from './BookingConfirmationForm'

function App() {
  const params = queryString.parse(window.location.search)
  const request = toRequest(params)

  return (
    <Container size='xs' mt='sm' mb='sm'>
      {request ? <BookingConfirmationForm {...request} />: <BookingRequestForm />}
    </Container>
  )
}

function toRequest(params: ParsedQuery): BookingRequest & { secret: string | null } | null {
  const { name, organization, email, phone, description, supplies, room, date, start, end, recurrance, until, secret } = params
  if (!name || Array.isArray(name) ||
      !organization || Array.isArray(organization) ||
      !email || Array.isArray(email) ||
      !phone || Array.isArray(phone) ||
      !description || Array.isArray(description) ||
      !supplies || Array.isArray(supplies) ||
      !room || Array.isArray(room) ||
      !date || Array.isArray(date) ||
      !start || Array.isArray(start) ||
      !end || Array.isArray(end) ||
      !recurrance || Array.isArray(recurrance) ||
      Array.isArray(until) ||
      Array.isArray(secret))
    return null
  return { name, organization, email, phone, description, supplies, room,
    date, start, end, recurrance: recurrance as Recurrance, until: until ?? undefined,
    secret }
}

export default App
