// Api utilities

const IS_IN_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const rootUrl = 'http://127.0.0.1:8787'
// const rootUrl = IS_IN_DEV ? 'http://127.0.0.1:8787' : 'https://booking_system.sydrc.workers.dev'
const baseUrl = `${rootUrl}/api/v1`

async function safeFetch<T>(input: RequestInfo, init: RequestInit): Promise<[number, T | null, string | null]> {
  try {
    const resp = await fetch(input, init)
    console.log("Received response")
    if (resp.status !== 200) {
      console.log("Response not 200 " + resp.status)
      return [resp.status, null, await resp.text()]
    }

    console.log("Response 200, its valid")
    return [resp.status, await resp.json(), null]
  } catch (e: any) {
    console.error('Unable to fetch', e)
    return [500, null, e.message]
  }
}

export { baseUrl, safeFetch }
