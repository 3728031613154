
export type RoomName = 'Conference Room' | 'Main Hall' | 'Garden Room' | 'IT Room'

export type RoomId = 8 | 4 | 7 | 9

const RoomToName: {
  [id in RoomId]: RoomName
} = {
  8: 'Garden Room',
  4: 'Main Hall',
  7: 'Conference Room',
  9: 'IT Room',
}

const NameToRoom: {
  [name in RoomName]: RoomId
} = {
  'Garden Room': 8,
  'Main Hall': 4,
  'Conference Room': 7,
  'IT Room': 9,
}

const RoomToColor: {
  [id in RoomId]: string
} = {
  8: '#DA77F2',
  4: '#38D9A9',
  7: '#A9E34B',
  9: '#F783AC',
}

function getRoomColor(id: RoomId): string {
  return RoomToColor[id] || '#909296'
}

export { RoomToName, NameToRoom, getRoomColor }
